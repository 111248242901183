import React, { useState, useEffect, useContext } from "react";
import { BrowserProvider, Contract, formatUnits, parseUnits, isAddress, parseEther } from "ethers";

import SETTINGS from "../SETTINGS";
import WaweSwapsABI from "../abis/WaweSwapsV3ABI";
import WaweSwapStorageABI from "../abis/WaweSwapStorageV3ABI";
import { ERC20_ABI } from "../abis/erc20";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import Dropdown from "react-bootstrap/Dropdown";
import { BiHome, BiCopy, BiArrowToBottom, BiCaretDown } from "react-icons/bi";
import LoadingDots from "./LoadingDots";

import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ToastNotification from "./ToastNotification";
import InfoModal from "./InfoModal";
import TooltipInfoReverse from "./TooltipInfoReverse";
//import Stats from "./Stats";

const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 20px;
  border: 0px solid var(--maincolor);
  padding: 20px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: #999999;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #999999;
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    
    border: 1px solid var(--maincolor);
    color: var(--maincolor);
    transition: all 0.6s;
  }
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = "accordionActive";
  const CLOSED = "accordionClosed";
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED}
      onClick={decoratedOnClick}
      style={{}}
    >
      <BiCaretDown /> {children}
    </button>
  );
}

function SwapV3({
  provider,
  selectedAccount,
  networkSymbol,
  isConnected,
  networkName,
  networkId,
  switchNetwork,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [ercBalance, setErcBalance] = useState(0);
  const [ercAlowance, setErcAlowance] = useState("");
  const [gblBalance, setGblBalance] = useState(0);
  const [swapV3Balance, setSwapV3Balance] = useState("");
  const [unclaimedGblRewards, setUnclaimedGblRewards] = useState(0);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
  const [swapTokenAmount, setSwapTokenAmount] = useState("");
  const [gblBuyAmount, setGblBuyAmount] = useState("");
  const [usdtBuyValue, setUsdtBuyValue] = useState("");
  const [usdtSellAmount, setUsdtSellAmount] = useState("");
  const [priceImpact, setPriceImpact] = useState(0);
  const [buyTokens, setBuyTokens] = useState([]);
  const [userSwaps, setUserSwaps] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [waveStakes, setWaveStakes] = useState([]);

  const [selectDisabled, setSelectDisabled] = useState(false);
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [showCollectGblModal, setShowCollectGblModal] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [loadingStep, setLoadingStep] = useState(1);
  const [loadingNumber, setLoadingNumber] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState("Select");

  const [refferalAddress, setRefferalAddress] = useState("");
  const [refferalDisabled, setRefferalDisabled] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastUrl, setToastUrl] = useState(null);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("-");

  const waweSwapV3Fees = {
    1: 0,
    10: 0,
    137: "500000000000000000",
    56: 0,
    42161: 0
  };
  const exportFeeAddresses = {
    10: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
    137: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
    56: "0x07dDF9A4230A0324317e062F9ccD666E26887Db2",
    42161: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
  };
  const exportFeeAmounts = {
    10: "0.0007",
    137: "2",
    56: "0.0032",
    42161: "0.0007"
  };
  const waweSwapV3Addresses = {
    1: "",
    10: SETTINGS.waweSwapAddressOptimism,
    137: SETTINGS.waweSwapAddressPolygon,
    56: SETTINGS.waweSwapAddressBsc,
    42161: SETTINGS.waweSwapAddressArbitrum,
  };
  const waweSwapStorageV3Addresses = {
    1: "",
    10: SETTINGS.waweSwapStorageAddressOptimism,
    137: SETTINGS.waweSwapStorageAddressPolygon,
    56: SETTINGS.waweSwapStorageAddressBsc,
    42161: SETTINGS.waweSwapStorageAddressArbitrum,
  };

  const waweSwapV3BuyTokens = {
    1: SETTINGS.buyTokensDataEthereum,
    10: SETTINGS.buyTokensDataOptimism,
    137: SETTINGS.buyTokensDataPolygon,
    56: SETTINGS.buyTokensData,
    42161: SETTINGS.buyTokensDataArbitrum,
  };

  const displayToast = (msg, url = null, error = false) => {
    setToastMessage(msg);
    setToastUrl(url);
    setToastError(error);
    setShowToast(true);
  };

  const handleSelect = (token) => {
    setSelectedLabel(`${token.symbol}`);
    handleTokenChange({ target: { value: token.address } });
  };

  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };
  const handleShowCollectModal = () => {
    setShowCollectModal(true);
  };

  const handleCloseCollectGblModal = () => {
    setShowCollectGblModal(false);
  };
  
  const handleShowCollectGblModal = () => {
    setShowCollectGblModal(true);
  };


  const getWaveStakes = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
        // Call getUserStakes from the contract
        const contract = new Contract(
          waweSwapV3Addresses[parseInt(networkId)],
          WaweSwapsABI,
          signer
        );
        const userStakes = await contract.getUserWaveStakes(userAddress);
      const stakesWithClaimable = await Promise.all(
        userStakes.map(async (stake, index) => {
          // Fetch claimable amount for each stake
          const claimableAmount = await contract.getClaimableWaveAmount(userAddress, index);
          return {
            amount: parseFloat(formatUnits(stake.amount.toString(), 18)).toFixed(6),
            startTime: new Date(parseInt(stake.startTime.toString()) * 1000).toLocaleString(),
            claimedAmount: formatUnits(stake.claimedAmount.toString(), 18),
            claimableAmount: parseFloat(formatUnits(claimableAmount.toString(), 18)).toFixed(6), // Added claimable amount
          };
        })
      );

      setWaveStakes(stakesWithClaimable);
    } catch (error) {
      console.error("Error fetching stakes:", error);
    }
  };
  const claimWaveStake = async (stakeIndex) => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
        // Call getUserStakes from the contract
        const contract = new Contract(
          waweSwapV3Addresses[parseInt(networkId)],
          WaweSwapsABI,
          signer
        );
  
      const tx = await contract.claimWave(stakeIndex,  {
        value: waweSwapV3Fees[parseInt(networkId)]
      });
      await tx.wait();
      alert("Claim successful!");
      await loadUserData();
      setIsLoading(false);
    } catch (error) {
      console.error("Error claiming stake:", error);
      setIsLoading(false);
    }
    
  };

  const closeSwap = async (_swapIndex) => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.openWaveStake(_swapIndex,  {
        value: waweSwapV3Fees[parseInt(networkId)]
      });

      await tx.wait(); // Wait for the transaction to be confirmed
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
      displayToast("Swap closed.", tx.hash);
    } catch (error) {
      console.error("Swap close failed", error);
      setIsLoading(false);
    }
    
  };

  const getBalance = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const balanceWei = await ethersProvider.getBalance(selectedAccount);
      const balanceEth = formatUnits(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const getBalanceERC = async (addr, user) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const TokenContract = new Contract(addr, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(user);
      const TokenDecimals = await TokenContract.decimals();
      return formatUnits(TokenBalance, TokenDecimals);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };

  useEffect(() => {
    var sp = new URLSearchParams(window.location.search);
    const ref = sp.get("reff");
    if (ref) {
      setRefferalAddress(ref);
      setRefferalDisabled(true);
    }

    if (selectedAccount) {
      firstLoad();
    } else {
      setGblBalance(0);
      setSwapV3Balance(0);
      setUserSwaps([]);

      showAvailableTokens();
    }

    if (parseInt(networkId) == 56) {
      handleSelect({
        symbol: "BNB",
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      });
    }
    if (parseInt(networkId) == 137) {
      handleSelect({
        symbol: "MATIC",
        address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      });
    }
    if (parseInt(networkId) == 42161) {
      handleSelect({
        symbol: "ETH",
        address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      });
    }
    if (parseInt(networkId) == 10) {
      handleSelect({
        symbol: "ETH",
        address: "0x4200000000000000000000000000000000000006",
      });
    }
  }, [selectedAccount, buyTokens, networkId]);

  const firstLoad = async () => {
    await loadUserData();

    showAvailableTokens();
    setIsLoading(false);
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const loadUserData = async () => {
    setGblBalance(
      await getBalanceERC(
        SETTINGS.gblAddresses[parseInt(networkId)],
        selectedAccount
      )
    );

    const contractBal = parseFloat(
      await getBalanceERC(
        SETTINGS.gblAddresses[parseInt(networkId)],
        waweSwapV3Addresses[parseInt(networkId)]
      )
    ).toFixed(2);
    setSwapV3Balance(numberWithCommas(contractBal));

    setErcBalance(await getBalance());
    setErcAlowance("-");

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );

      const myRefferer = await contract.referrals(selectedAccount);
      if (myRefferer !== "0x0000000000000000000000000000000000000000") {
        setRefferalAddress(myRefferer);
        setRefferalDisabled(true);
      }

      if (parseInt(networkId) === 137) {
        const contractOldPolygon = new Contract(
          "0x48b9b7761f6bc5c3e560350b9D7c904FF8e9E5DF",
          WaweSwapsABI,
          signer
        );
        const myReffererOldPolygon = await contractOldPolygon.referrals(
          selectedAccount
        );
        if (
          myReffererOldPolygon !== "0x0000000000000000000000000000000000000000"
        ) {
          setRefferalAddress(myReffererOldPolygon);
          setRefferalDisabled(true);
        }

        const contractOldPolygonCustom = new Contract(
          SETTINGS.waweSwapAddressPolygonOld,
          WaweSwapsABI,
          signer
        );
        const myReffererOldPolygonCustom =
          await contractOldPolygonCustom.referrals(selectedAccount);
        if (
          myReffererOldPolygonCustom !==
          "0x0000000000000000000000000000000000000000"
        ) {
          setRefferalAddress(myReffererOldPolygonCustom);
          setRefferalDisabled(true);
        }
      }

      if (parseInt(networkId) === 56) {
        const contractOldBsc = new Contract(
          SETTINGS.waweSwapAddressBscOld,
          WaweSwapsABI,
          signer
        );
        const myReffererOldBsc = await contractOldBsc.referrals(
          selectedAccount
        );
        if (myReffererOldBsc !== "0x0000000000000000000000000000000000000000") {
          setRefferalAddress(myReffererOldBsc);
          setRefferalDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error fetching referral data:", error);
    }

    console.log("Loading user data");
    //const wawe_stakes = await getWaveStakes(selectedAccount);
    const swaps = await getSwapsForUser(selectedAccount);
    setUserSwaps(swaps);

    setIsLoading(false);
  };

  const getTimeLeft = (createdAt) => {
    // Convert createdAt to milliseconds for the start time and add 30 days for the end time
    const endTime = new Date((createdAt + 30 * 24 * 60 * 60) * 1000);

    // Current time
    const now = new Date();

    // Calculate the difference in milliseconds
    const timeLeft = endTime - now;

    // Convert time left into days and hours
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    // Return a string with days and hours left
    return `${daysLeft} days and ${hoursLeft} hours left`;
  };
  

  const getSwapsForUser = async (userAddress) => {

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const contractStorage = new Contract(
        waweSwapStorageV3Addresses[parseInt(networkId)],
        WaweSwapStorageABI,
        signer
      );

      // Set token decimals based on the network


      const userSwaps = await contractStorage.getSwaps(userAddress);
      console.log(userSwaps);
      const userRewardAmountWei = await contractStorage.usdtRewards(
        userAddress
      );
      const userGblRewardAmountWei = await contractStorage.gblRewards(
        userAddress
      );

      let tokenDecimals = parseInt(networkId) === 56 ? 18 : 6;
      const userRewardAmount = parseFloat(
        formatUnits(
          userRewardAmountWei,
          tokenDecimals === 18 ? "ether" : "mwei"
        )
      );
      const userGblRewardAmount = parseFloat(
        formatUnits(userGblRewardAmountWei, "ether")
      );

      let swaps = [];

      for (let i = 0; i < userSwaps.length; i++) {
        console.log(userSwaps[i]);
        const amount = parseFloat(formatUnits(userSwaps[i][0], "ether"));
        const startValue = parseFloat(
          formatUnits(userSwaps[i][2], tokenDecimals === 18 ? "ether" : "mwei")
        );
        const sellAmount = parseFloat(
          formatUnits(userSwaps[i][1], tokenDecimals === 18 ? "ether" : "mwei")
        );
        const createdAt = parseInt(userSwaps[i][4]);

        let feePercentage = getFeePercentage(startValue);
        let maxSellAmount = startValue * parseFloat(feePercentage);
        if (createdAt <= 1719865467) {
          maxSellAmount = startValue;
        }

        let soldAmount = maxSellAmount - sellAmount;
        if (soldAmount < 0) soldAmount = 0;
        const swapIndex = parseInt(userSwaps[i][5]);

        swaps.push({
          uid: i,
          swapIndex: swapIndex,
          amount: amount.toFixed(2),
          startValue: startValue.toFixed(2),
          sellAmount: sellAmount.toFixed(2),
          maxSellAmount: maxSellAmount.toFixed(2),
          soldAmount: soldAmount.toFixed(2),
          timeLeft: getTimeLeft(parseInt(userSwaps[i][4])),
        });
      }

      setUnclaimedRewards(userRewardAmount.toFixed(4));
      setUnclaimedGblRewards(userGblRewardAmount.toFixed(4));

      let feePercentage;
      if (userRewardAmount < 100) {
        feePercentage = 0.02; // 2% for less than 100 ether
      } else if (userRewardAmount >= 100 && userRewardAmount < 1000) {
        feePercentage = 0.01; // 1% for 100 to 1000 ether
      } else {
        feePercentage = 0.005; // 0.5% for 1000 ether or more
      }

      const feeAmount = userRewardAmount * feePercentage;
      const netRewardAmount = userRewardAmount - feeAmount;
      setUnclaimedRewardsFee(feeAmount.toFixed(4));
      setUnclaimedRewardsNet(netRewardAmount.toFixed(4));

      return swaps.reverse();
    } catch (error) {
      console.error("Error fetching swap data:", error);
      return [];
    }
  };
  const handleTokenChange = async (e) => {
    const selectedAddress = e.target.value;

    if (selectedAddress === "") {
      setSelectedToken(null);
      setSelectDisabled(false);
    } else {
      if(!buyTokens) return;
      const token = buyTokens.find(
        (token) => token.address === selectedAddress
      );
      if (!token) return;

      setSelectedToken(token);

      if (token.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setErcBalance(await getBalance());
        setErcAlowance("-");
      } else {
        setErcBalance(await getBalanceERC(token.address, selectedAccount));

        try {
          const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const ercContract = new Contract(token.address, ERC20_ABI, signer);

          const allowanceERC = await ercContract.allowance(
            selectedAccount,
            waweSwapV3Addresses[parseInt(networkId)]
          );
          const decimals = await ercContract.decimals();
          const formattedBalance = parseFloat(
            formatUnits(allowanceERC, decimals)
          );

          setErcAlowance(formattedBalance.toFixed(4));
        } catch (error) {
          console.error("Error fetching token allowance:", error);
          setErcAlowance("0");
        }
      }

      setSwapTokenAmount("");
      setGblBuyAmount(0);
      setUsdtSellAmount(0);
      setUsdtBuyValue(0);
      setSelectDisabled(false);
    }

    setSwapAvailable(false);
  };

  const getPriceNetwork = async (tokenAddress) => {
    const routerABI = [
      {
        constant: true,
        inputs: [
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256",
          },
          {
            internalType: "address[]",
            name: "path",
            type: "address[]",
          },
        ],
        name: "getAmountsOut",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];

    const usdtAddress = SETTINGS.usdtAddress[parseInt(networkId)];
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const routerContract = new Contract(
      "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      routerABI,
      signer
    );

    const amountIn = parseUnits("1", 18); // This is for tokens with 18 decimals

    try {
      const amountsOut = await routerContract.getAmountsOut(amountIn, [
        tokenAddress,
        usdtAddress,
      ]);

      let priceInUSDT = amountsOut[1];

      return priceInUSDT;
    } catch (error) {
      console.error("Error fetching price:", error);
      return 999;
    }
  };
  const getUniswapV3PriceUSDT = async (tokenAddress, poolFee) => {
    if (parseInt(networkId) == 56) {
      const p = await getPriceNetwork(tokenAddress);
      return p;
    }

    const usdtAddress = SETTINGS.usdtAddress[parseInt(networkId)];

    const quoterABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address",
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address",
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24",
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256",
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160",
          },
        ],
        name: "quoteExactInputSingle",
        outputs: [
          {
            internalType: "uint256",
            name: "amountOut",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ]; // Uniswap V3 Quoter ABI

    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const quoterContract = new Contract(
      SETTINGS.quoterAddress[parseInt(networkId)],
      quoterABI,
      signer
    );

    const amountIn = parseUnits("1", 18); // Assuming the input token has 18 decimals

    try {
      // Assuming quoteExactInputSingle is the correct method; adjust as necessary
      const quote = await quoterContract.quoteExactInputSingle(
        tokenAddress,
        usdtAddress,
        poolFee,
        amountIn,
        0
      );

      // Return the price as a string with up to 6 decimal places
      return quote.toString();
    } catch (error) {
      console.error("Error fetching price:", error);
      return "9999999999"; // Indicative of an error state, adjust as necessary
    }
  };

  const handleRefferalAddressChange = async (e) => {
    const addr = e.target.value;
    if (addr.length === 0) return;



    if (!isAddress(addr)) {
      displayToast("Wrong address type", null, true);
      setRefferalAddress("");
      return;
    }

    if (addr.toLowerCase() === selectedAccount.toLowerCase()) {
      displayToast("You can't use your own address.", null, true);
      setRefferalAddress("");
      return;
    }

    setRefferalAddress(addr);
  };

  const handleBuyAmountChange = async (e) => {
    const amount = e.target.value;
    setSwapTokenAmount(amount);
    setGblBuyAmount(0);
    setUsdtSellAmount(0);
    setUsdtBuyValue(0);

    if (amount === "") {
      setSwapAvailable(false);
    } else {
      if (parseFloat(amount) > parseFloat(ercBalance)) {
        setErrorText("Balance too low!");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setSwapAvailable(false);
      } else {
        setErrorText("");
        let dec = parseInt(networkId) === 56 ? 18 : 6;

        let tokenPriceUSDT = parseUnits("1", dec); // This assumes a default token price of 1 USDT
        if (selectedToken.symbol !== "USDT") {
          tokenPriceUSDT = await getUniswapV3PriceUSDT(
            selectedToken.address,
            10000
          );
        }

        const gblPriceUSDT = await getUniswapV3PriceUSDT(
          SETTINGS.gblAddresses[parseInt(networkId)],
          10000
        );

        const tokenPriceUSDTDecimal =
          parseFloat(tokenPriceUSDT) / Math.pow(10, dec);
        const gblPriceUSDTDecimal =
          parseFloat(gblPriceUSDT) / Math.pow(10, dec);
        const amountInUSDT = parseFloat(amount) * tokenPriceUSDTDecimal;

        const amountGblToBuy = amountInUSDT / gblPriceUSDTDecimal;

        let feePercentage = getFeePercentage(amountInUSDT);
        setPriceImpact(((feePercentage - 1) * 100).toFixed(0));
        setGblBuyAmount(parseFloat(amountGblToBuy).toFixed(6));
        setUsdtBuyValue(parseFloat(amountInUSDT).toFixed(6));
        setUsdtSellAmount(
          (parseFloat(amountInUSDT) * feePercentage).toFixed(6)
        );

        setSwapAvailable(true);
      }
    }
  };

  const getFeePercentage = (amountInUSDT) => {
    let feePercentage = 1.1;

    if (parseFloat(amountInUSDT) < 100) {
      feePercentage = 1.5;
    } else if (
      parseFloat(amountInUSDT) >= 100 &&
      parseFloat(amountInUSDT) < 250
    ) {
      feePercentage = 1.4;
    } else if (
      parseFloat(amountInUSDT) >= 250 &&
      parseFloat(amountInUSDT) < 750
    ) {
      feePercentage = 1.3;
    } else if (
      parseFloat(amountInUSDT) >= 750 &&
      parseFloat(amountInUSDT) < 1000
    ) {
      feePercentage = 1.2;
    } else if (parseFloat(amountInUSDT) >= 1000) {
      feePercentage = 1.1;
    }
    return feePercentage;
  };
  const reopenSwap = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.reopenSwap(refferalAddress);

      await tx.wait(); // Wait for the transaction to be confirmed
      await loadUserData();
      setIsLoading(false);
      displayToast("Stake reopened.", tx.hash);
    } catch (error) {
      console.error("Stake failed", error);
      setIsLoading(false);
    }
  };

  const collectIncome = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claimUsdtReward({
        gasLimit: 300000, // Set an appropriate gas limit
        from: selectedAccount,
      });

      await tx.wait(); // Wait for the transaction to be confirmed
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
      displayToast("Income claimed.", tx.hash);
    } catch (error) {
      console.error("Claim failed", error);
      setIsLoading(false);
    }
  };

  const collectGblIncome = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const tx = await contract.claimGblReward({
        gasLimit: 300000, // Set an appropriate gas limit
        from: selectedAccount,
      });

      await tx.wait(); // Wait for the transaction to be confirmed
      await loadUserData();
      handleCloseCollectGblModal();
      setIsLoading(false);
      displayToast("Income claimed.", tx.hash);
    } catch (error) {
      console.error("Claim failed", error);
      setIsLoading(false);
    }
  };

  const handleSwap = async () => {
    setIsLoading(true);

    if (parseInt(networkId) !== 56) {
      await handleSwapOthers();
      return;
    }

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE();

      if (selectedToken.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setLoadingNumber(1);
        let refAddr = SETTINGS.genessisAddress;
        if (refferalAddress !== "") {
          refAddr = refferalAddress;
        }

        // Calculate the BNB amount in Wei
        const swapAmountWithFee =
          parseFloat(swapTokenAmount) +
          parseFloat(formatUnits(fee.toString(), "ether"));
        const bnbAmountInWei = parseUnits(
          swapAmountWithFee.toString(),
          "ether"
        );
        setLoadingStep(1);
        setLoadingText("Starting V3 GBL Bot");

        const tx = await contract.buySwapWithETH(refAddr, {
          from: selectedAccount,
          value: bnbAmountInWei, // Send BNB along with the transaction
        });

        await tx.wait(); // Wait for the transaction to be confirmed
        await loadUserData();
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setIsLoading(false);
        displayToast("Position Opened!", tx.hash);
      }
    } catch (error) {
      console.error("Swap failed", error);
      displayToast(error.message, null, true);
    } finally {
      // Ensure user data is reloaded and UI is updated accordingly
      setSwapAvailable(false);
      setIsLoading(false);
    }
  };

  const copyRefferalLink = async () => {
    const text = "https://waveswaps.com/?v=3&reff=" + selectedAccount;
    navigator.clipboard.writeText(text);
    displayToast("Link coppied!");
  };

  const handleSwapOthers = async () => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        waweSwapV3Addresses[parseInt(networkId)],
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE();

      if (selectedToken.symbol.toLowerCase() === networkSymbol.toLowerCase()) {
        setLoadingNumber(1);

        // Calculate the BNB amount in Wei
        const swapAmountWithFee =
          parseFloat(swapTokenAmount) +
          parseFloat(formatUnits(fee.toString(), "ether"));
        const bnbAmountInWei = parseUnits(
          swapAmountWithFee.toString(),
          "ether"
        );

        setLoadingStep(1);
        setLoadingText("Starting V3 GBL Bot");

        let refAddr = SETTINGS.genessisAddress;
        if (refferalAddress !== "") {
          refAddr = refferalAddress;
        }

        const gblAmountSlippage =
          parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
        const gblAmountInWei = parseUnits(gblBuyAmount.toString(), "ether");
        const gblAmountSlippageInWei = parseUnits(
          gblAmountSlippage.toString(),
          "ether"
        );

        const buyValueSlippage =
          parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
        const buyValueInWei = parseUnits(buyValueSlippage.toString(), "ether");
        const sellAmountInWei = parseUnits(usdtSellAmount.toString(), "ether");

        const tx = await contract.buySwapWithETH(refAddr, {
          from: selectedAccount,
          value: bnbAmountInWei, // Send BNB along with the transaction
        });

        await loadUserData();
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setUsdtSellAmount(0);
        setUsdtBuyValue(0);
        setIsLoading(false);
        displayToast("Position Opened!", tx.hash);
      } else {
        setLoadingNumber(2);
        setLoadingStep(1);
        setLoadingText("Approving " + selectedToken.symbol + " transaction.");

        const tokenContract = new Contract(
          selectedToken.address,
          ERC20_ABI,
          signer
        );
        const tokenDecimals = await tokenContract.decimals();
        const amountInTokenUnits = swapTokenAmount; // The token amount the user wants to swap
        const amountInWei = parseUnits(
          amountInTokenUnits.toString(),
          tokenDecimals
        );

        try {
          const allowanceToken = await tokenContract.allowance(
            selectedAccount,
            waweSwapV3Addresses[parseInt(networkId)]
          );
          
            await tokenContract.approve(
              waweSwapV3Addresses[parseInt(networkId)],
              amountInWei
            );
          

          setLoadingStep(2);
          setLoadingText("Starting V3 GBL Bot");

          const gblAmountSlippage =
            parseFloat(gblBuyAmount) - (parseFloat(gblBuyAmount) / 100) * 50;
          const gblAmountInWei = parseUnits(gblBuyAmount.toString(), "ether");
          const gblAmountSlippageInWei = parseUnits(
            gblAmountSlippage.toString(),
            "ether"
          );

          const buyValueSlippage =
            parseFloat(usdtBuyValue) - (parseFloat(usdtBuyValue) / 100) * 1;
          const buyValueInWei = parseUnits(
            buyValueSlippage.toString(),
            "ether"
          );

          const tx = await contract.buySwap(
            selectedToken.address,
            amountInWei,
            gblAmountInWei,
            buyValueInWei,
            gblAmountSlippageInWei
          );

          await loadUserData();
          setSwapTokenAmount("");
          setGblBuyAmount(0);
          setUsdtSellAmount(0);
          setUsdtBuyValue(0);
          setIsLoading(false);
          displayToast("Position Opened!", tx.hash);
        } catch (error) {
          console.error("Send failed", error);
          displayToast(error.message, null, true);
        }
      }
    } catch (error) {
      await loadUserData();
      console.error("Swap failed", error);
      displayToast(error.message, null, true);
    } finally {
      
      setSwapAvailable(false);
      setIsLoading(false);
    }
  };

  const showAvailableTokens = () => {
    setBuyTokens(waweSwapV3BuyTokens[parseInt(networkId)]);
  };


  const exportSwapsUser = async () => {
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Exporting swaps.");

    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const feeAddress = exportFeeAddresses[parseInt(networkId)];
    const feeAm = parseEther(exportFeeAmounts[parseInt(networkId)]);
    console.log(feeAddress);
    console.log(feeAm);
    // Transfer feeAm of native currency to feeAddress
    const transaction = await signer.sendTransaction({
        to: feeAddress,
        value: feeAm
    });

    // Wait for the transaction to be mined
    await transaction.wait();

    await exportSwaps();
    setIsLoading(false);
}


  const exportSwaps = async () => {
    // Set up provider and contract
    const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

    
    const waweSwapStorageV3Address = waweSwapStorageV3Addresses[parseInt(networkId)];
    const waweSwapV3Address = waweSwapStorageV3Addresses[parseInt(networkId)];
    const contractStorage = new Contract(waweSwapStorageV3Address, WaweSwapStorageABI, signer);
    const contract = new Contract(
      waweSwapV3Addresses[parseInt(networkId)],
      WaweSwapsABI,
      signer
    );
    // Call the function to get all open swaps
    let allOpenSwaps = await contractStorage.getAllOpenSwaps();
    let csvResult = "Address;Swapped_Value;Target_Value;Swap_Value;GBL_Liquidity\n";
    let swapsArr = [];
    let usersArr = [];
    let fullAmount = 0;
    let stakesArrUsers = [];
    let stakesArrAmounts = [];
    let stakesArrStartTimes = [];
    let stakesArrClaimed = [];
    for (let i = 0; i < allOpenSwaps.length; i++) {
      try{
      let swap = allOpenSwaps[i];
      if (swap[3] === "0x0000000000000000000000000000000000000000") continue;
      
      let tokenDecimals = parseInt(networkId) === 56 ? "ether" : "mwei";
      const swapAmount = formatUnits(swap[0], "ether");
      const sellAmount = formatUnits(swap[1], tokenDecimals);
      const startValue = formatUnits(swap[2], tokenDecimals);
      const addr = swap[3];

      const createdAt = parseInt(userSwaps[i][4]);
      let feePercentage = getFeePercentage(startValue);
      let maxSellAmount = startValue * parseFloat(feePercentage);
      if (createdAt <= 1719865467) {
        maxSellAmount = startValue;
      }
      
     
        
        /*
        
      const userRewardAmountWei = await contractStorage.usdtRewards(addr);

      let tokenDecimals = parseInt(networkId) === 56 ? 18 : 6;
      const userRewardAmount = parseFloat(
        formatUnits(
          userRewardAmountWei,
          tokenDecimals === 18 ? "ether" : "mwei"
        )
      );
      
      fullAmount += userRewardAmount;
      */

      swapsArr.push([swapAmount, sellAmount, startValue, addr]);
  
      csvResult += `${addr};${sellAmount};${maxSellAmount};${startValue};${swapAmount}\n`;
     
      
    }catch (error) {
      console.error("wrong err", error);
    }
    }
    console.log(stakesArrUsers);
    console.log(stakesArrAmounts);
    console.log(stakesArrStartTimes);
    console.log(stakesArrClaimed);
    // Log swaps array
    //console.log("swapsArr", JSON.stringify(swapsArr));
  
    // Generate CSV file and download it
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV3_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  return (
    <div className="waweSwap" style={{marginBottom:"100px", marginTop:"50px"}}>
    {isLoading && (
      <div className="gameLoader">
        <br />
        <br />
        <br />

        <br />
        <div className="gameSpinner">
        <Spinner animation="border" role="status" className="loaderBig" />
        <center>
                        <p>
                          <b>
                            {loadingStep} / {loadingNumber}
                          </b>{" "}
                          <br /> {loadingText}
                        </p>
                      </center>
        </div>
        </div>
    )}
      <ToastNotification
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        url={toastUrl}
        error={toastError}
        networkId={networkId}
      />

  
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 ">
            {selectedAccount && (
              <>
                {selectedAccount.toLowerCase() ===
                  SETTINGS.ownerAddress.toLowerCase() && (
                  <button onClick={exportSwaps}>Export Admin</button>
                )}
              </>
            )}
            {/*  <div className='card' >
    <div className='card-body'>
    <div className='row'>
<div className='col-6'><img src={networkIcons[parseInt(networkId)]} alt="" style={{ width: '15px', marginRight:"10px" }} /><label className="form-label"> {networkNames[parseInt(networkId)]} V3 swapping pool</label></div>
      
      <div className='col-6'><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight:"10px" }} /><label className="form-label"> <small className='smallTitleSmall'>{swapV3Balance} <small className='smallText'> GBL</small></small></label></div>

</div>
</div>
</div>*/}
            <div className="card page-container">
              <div className="card-body">
                <form className="text-center">

                    <div>
                    <button  style={{position:"absolute", right:"50px", top:"6px"}}
                      onClick={(event) => {
                        event.preventDefault(); // Prevent the default form submission behavior
                        exportSwapsUser(); // Call your function
                      }}>Export</button>
                        <InfoModal
        link="https://docs.waveswaps.com/swaps/v3-gbl-bot"
        message="Developed from the Swap V3, the V3 GBL Bot offers users the opportunity to increase their deposited liquidity provision value by 10% to 50%, potentially providing stable returns in the form of a stable coin. Additionally, purchasing GBL coin increases liquidity, contributing to market stability and reducing volatility. Users can now claim remaining balances through WaveCoin. However, this is an additional, optional feature. If not chosen, swaps will continue processing as set innitially."
      />

                      <div>
                        <h4>Revitalize GBL Tokens</h4>
                        {selectedAccount && (
                          <div
                            className="bot-card-header"
                            style={{
                              width: "100%",
                              backgroundColor: "#7da7f0",
                              padding: "20px",
                            }}
                          >
                            <label
                              className="form-label"
                              style={{ color: "#FFFFFF" }}
                            >
                              Earn 10% from referrals' swaps!
                            </label>
                            <button
                              className="mediumButton"
                              type="button"
                              onClick={copyRefferalLink}
                              style={{ float: "right", marginTop: "15px" }}
                            >
                              <BiCopy
                                className="smallIconGray"
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              />
                              Copy{" "}
                            </button>
                          </div>
                        )}
                   
                        <div className="pokemon-card">
                          <label className="form-label">
                            Liquidity Provision
                          </label>
                          <div className="row">
                            <div className="col-6 col-md-8 col-lg-8">
                              <input
                                id="tokenAmount"
                                type="text"
                                className="form-control"
                                placeholder="0"
                                value={swapTokenAmount}
                                onChange={handleBuyAmountChange}
                              />
                              <label className="form-label">
                                <small>Balance: </small> {ercBalance}
                              </label>
                              <br />
                            </div>
                            <div className="col-6 col-md-4 col-lg-4">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary"
                                  id="tokenSelect"
                                  disabled={selectDisabled}
                                  className="dropdownStyle"
                                >
                                  <img
                                    src={SETTINGS.tokenIcons[selectedLabel]}
                                    alt=""
                                    style={{
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {selectedLabel}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdownMenuStyle">
                                  {Array.isArray(buyTokens) &&
                                  buyTokens.length > 0 ? (
                                    buyTokens.map((token) => (
                                      <Dropdown.Item
                                        key={token.address}
                                        onClick={() => handleSelect(token)}
                                      >
                                        <img
                                          src={
                                            SETTINGS.tokenIcons[token.symbol]
                                          }
                                          alt=""
                                          style={{
                                            width: "20px",
                                            marginRight: "10px",
                                          }}
                                        />
                                        {token.symbol}
                                      </Dropdown.Item>
                                    ))
                                  ) : (
                                    <Dropdown.Item disabled>
                                      No tokens available
                                    </Dropdown.Item> // Optional: display a message when no tokens are available
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <center>
                          <i className="smallIconGray">
                            <BiArrowToBottom />
                          </i>
                        </center>
                      </div>
                      <div className="mb-3">
                        <div className="pokemon-card">
                          <div className="row">
                            <div className="col-1">
                              <img
                                src={SETTINGS.tokenIcons["GBL"]}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            </div>
                            <div className="col-5">
                              <label className="form-label">
                                GBL liquidity:{" "}
                              </label>
                            </div>
                            <div className="col-6">
                              <label className="form-label">
                                {" "}
                                {gblBuyAmount} <small>GBL</small>
                              </label>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-1">
                              <img
                                src={SETTINGS.tokenIcons["USDT"]}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            </div>
                            <div className="col-5">
                              <label className="form-label">Swap value: </label>
                            </div>
                            <div className="col-6">
                              <label className="form-label">
                                {" "}
                                {usdtBuyValue} <small>USDT</small>
                              </label>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-1">
                              <img
                                src={SETTINGS.tokenIcons["USDT"]}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            </div>
                            <div className="col-5">
                              <label className="form-label">
                                Swapping income: <TooltipInfoReverse />{" "}
                              </label>
                            </div>
                            <div className="col-6">
                              <label className="form-label">
                                {" "}
                                {priceImpact} <small>%</small>
                              </label>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-1">
                              <img
                                src={SETTINGS.tokenIcons["USDT"]}
                                alt=""
                                style={{ width: "15px" }}
                              />
                            </div>
                            <div className="col-5">
                              <label className="form-label">
                                Target value:{" "}
                              </label>
                            </div>
                            <div className="col-6">
                              <label className="form-label">
                                {" "}
                                {usdtSellAmount} <small>USDT</small>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="pokemon-card">
                            <div className="row">
                              <div className="col-12 col-md-8 col-lg-8">
                                <label className="form-label">
                                  Referral address
                                </label>
                                <input
                                  id="refferalAddress"
                                  type="text"
                                  className="form-control"
                                  placeholder="0x.. (Optional)"
                                  value={refferalAddress}
                                  onChange={handleRefferalAddressChange}
                                  style={{ fontSize: "12px", color: "#0b4182" }}
                                  disabled={refferalDisabled}
                                />
                              </div>
                              <div className="col-12 col-md-4 col-lg-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-grid gap-2"  style={{textAlign:"left"}}>
                        <small
                          className="smallText text-left"
                          style={{ paddingBottom: "20px" }}
                        >
                          The referral address used for the first time will
                          remain permanently linked to your current wallet.
                          <br />
                          When a referral address is not linked or added, the
                          swap will automatically use and allocate the Genesis
                          referral address.
                          <br />
                          By clicking the button "Start V3 GBL Bot", you agree with
                          WaveSwaps{" "}
                          <a
                            href="https://docs.waveswaps.com/ws-official/terms-and-conditions"
                            target="_blank"
                          >
                            Terms and Conditions.
                          </a>
                        </small>
                      </div>
                    </div>
                
                  <div className="d-grid gap-2">
                    {swapAvailable ? (
                      <div>
                      
                          <button
                            className="large-button-blue"
                            type="button"
                            onClick={handleSwap}
                          >
                            Start V3 GBL Bot
                          </button>
                      
                      </div>
                    ) : (
                      <div>
                        <button
                          className="large-button-blue-disabled"
                          type="button"
                        >
                          Start V3 GBL Bot
                        </button>
                        <center>
                          <div className="error-text error-text">
                            {errorText}
                          </div>
                        </center>
                      </div>
                    )}
                  </div>
                  <div className="d-grid gap-2">
                    <small
                      className="smallText text-left"
                      style={{ paddingTop: "20px" }}
                    >
                      Swapping income is distributed when enough total native currency
                      has accumulated to execute the distribution function
                      within the V3 GBL Bot smart contract.
                    </small>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12"></div>
          <div className="col-12 col-md-8 col-lg-6">
            <br />

            <div className="row">
              <div className="col-3 col-lg-4">
                <small className="smallTitle">USDT Rewards:</small>
              </div>
              <div className="col-6 col-lg-4">
                <img
                  src={SETTINGS.tokenIcons["USDT"]}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                />{" "}
                {unclaimedRewards} <small>USDT</small>
              </div>
              <div className="col-3 col-lg-4">
                <button
                  type="button"
                  className="hero-button"
                  style={{ marginTop: "0px" }}
                  onClick={handleShowCollectModal}
                >
                  Claim
                </button>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-3 col-lg-4">
                <small className="smallTitle">GBL rewards:</small>
              </div>
              <div className="col-6 col-lg-4">
                <img
                  src={SETTINGS.tokenIcons["GBL"]}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                />{" "}
                {unclaimedGblRewards} <small>GBL</small>
              </div>
              <div className="col-3 col-lg-4">
                <button
                  type="button"
                  className="hero-button"
                  style={{ marginTop: "0px" }}
                  onClick={handleShowCollectGblModal}
                >
                  Claim
                </button>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-8 col-lg-8">
                <small className="smallTitle">
                  Restart bot with your income:
                </small>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  className="start-button"
                  style={{ marginTop: "0px" }}
                  onClick={reopenSwap}
                >
                  Start V3 GBL Bot
                </button>
              </div>
            </div>
            {/* <Stats swaps={userSwaps} /> */}
            <div>
      
            {waveStakes.length > 0 ? (
  <>
    <h5 className="mb-3 mt-5">WAVE Claim:</h5>
    <div className="col-12">
      <small className="smallText text-left">
      Your list of migrated and canceled V3 GBL Bots, releasing a fixed, proportional WAVE amount daily over 90 days.
      </small><br /><br />
    </div>
    <Accordion defaultActiveKey="0">
      {waveStakes.map((stake, index) => (
        <Card key={index}>
          <Card.Header>
            <div className="row">
              <div className="col-5">
                <small
                  className="smallTitleSmall"
                  style={{ lineHeight: "30px", marginTop: "5px" }}
                >
                  <img
                    src={SETTINGS.tokenIcons["WAVE"]}
                    alt=""
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      marginTop: "-3px",
                    }}
                  />{" "}
                  {stake.amount} WAVE
                </small>
              </div>
              <div className="col-5">
                <small
                  className="smallTitleSmall"
                  style={{ lineHeight: "30px", marginTop: "5px" }}
                >
                  <img
                    src={SETTINGS.tokenIcons["WAVE"]}
                    alt=""
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      marginTop: "-3px",
                    }}
                  />{" "}
                  Claimable: {stake.claimableAmount} WAVE
                </small>
              </div>
              <div className="col-1">
                <ContextAwareToggle eventKey={String(index)} />
              </div>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey={String(index)}>
            <Card.Body>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label className="form-label">Start Time</label>
                  <br />
                  <small>{stake.startTime}</small>
                </div>
                <div className="col-12 col-lg-6">
                  <label className="form-label">Claimed Amount</label>
                  <br />
                  <small>{parseFloat(stake.claimedAmount).toFixed(4)} WAVE</small>
                </div>
                <div className="col-12">
                  {parseFloat(stake.claimableAmount) > 0 && (
                    <button
                      type="button"
                      className="hero-button"
                      onClick={() => claimWaveStake(index)}
                      style={{ marginTop: "10px" }}
                    >
                      Claim WAVE
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  </>
) : (
  <></>
)}

    </div>
    <h5 className="mb-3 mt-5">V3 GBL Bot(s):</h5>
    <div className="col-12">
      <small className="smallText text-left">
      Your active V3 GBL Bots, designed to maximize liquidity and streamline token recycling through automated processes.
      </small><br /><br />
    </div>
            <Accordion defaultActiveKey="0">
              {userSwaps.map((swap, index) => (
                <Card key={index}>
                  <Card.Header>
                    <div className="row ">
                      <div className="col-5">
                        <small
                          className="smallTitleSmall"
                          style={{ lineHeight: "30px", marginTop: "5px" }}
                        >
                          {" "}
                          <img
                            src={SETTINGS.tokenIcons["USDT"]}
                            alt=""
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              marginTop: "-3px",
                            }}
                          />{" "}
                          {swap.soldAmount}{" "}
                          <small className="smallText"> USDT</small>
                        </small>
                      </div>

                      <div className="col-1"></div>
                      <div className="col-5">
                        <small
                          className="smallTitleSmall"
                          style={{ lineHeight: "30px", marginTop: "5px" }}
                        >
                          {" "}
                          <img
                            src={SETTINGS.tokenIcons["USDT"]}
                            alt=""
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              marginTop: "-3px",
                            }}
                          />
                          {swap.maxSellAmount}{" "}
                          <small className="smallText"> USDT</small>{" "}
                        </small>
                      </div>
                      <div className="col-1">
                        {" "}
                        <ContextAwareToggle
                          eventKey={String(index)}
                        ></ContextAwareToggle>
                      </div>

                      <div className="col-12">
                        <ProgressBar
                          now={swap.soldAmount}
                          min={0}
                          max={swap.maxSellAmount}
                          striped
                          variant="info"
                          animated
                          className="swapProgress"
                        ></ProgressBar>
                      </div>
                    </div>
                  </Card.Header>

                  <Accordion.Collapse eventKey={String(index)}>
                    <Card.Body>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <label className="form-label">Swap value: </label>
                          <small>
                            {" "}
                            <img
                              src={SETTINGS.tokenIcons["USDT"]}
                              alt=""
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                            {swap.startValue} USDT
                          </small>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 col-lg-6">
                          <label className="form-label">Outstanding:</label>
                          <small>
                            {" "}
                            <img
                              src={SETTINGS.tokenIcons["USDT"]}
                              alt=""
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                            {swap.sellAmount} USDT
                          </small>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 col-lg-6">
                          <label className="form-label">Swapping: </label>
                          <small>
                            {" "}
                            <img
                              src={SETTINGS.tokenIcons["GBL"]}
                              alt=""
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                            {swap.amount} GBL
                          </small>
                          <br />
                          <br />
                        </div>
                        {/*
                        {(parseInt(networkId) === 56 || parseInt(networkId) === 137) && (
                          <div className="col-12 col-lg-6">
                          <label className="form-label">Migrate to WAVE:</label>
                          <br />

                          <button
                            type="button"
                            className="claimButton3"
                            style={{ marginTop: "0px" }}
                            onClick={() => closeSwap(swap.swapIndex)} // Wrap in an anonymous function
                          >
                            Close swap and claim {swap.sellAmount} WAVE through 100 days.
                          </button>
                          
                          <br />
                          <br />
                          
                        </div> )}
 */}
                        {parseInt(networkId) == 57 && (
                          <div className="col-12 col-lg-4">
                            <label className="form-label">Earned VGBL </label>
                            <br />
                            <small>
                              {" "}
                              <img
                                src={SETTINGS.tokenIcons["VGBL"]}
                                alt=""
                                style={{ width: "15px", marginRight: "10px" }}
                              />
                              {swap.amount} VGBL
                            </small>
                          </div>
                          
                        )}
                        <div className="col-12">
                          <small className="smallText gbl-cashback text-left">
                            *50% of GBL swapped will be credited to your GBL
                            Income at the completion of each swap.{" "}
                          </small>
                          <br />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </div>


      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 ">
          <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                <center>Claim Income</center>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card ">
                <div className="card-body text-center">
                <small>Available USDT Income:</small>
                <br />
                <input type="text" value={unclaimedRewards} disabled style={{width:"100%", textAlign:"center"}} />
                <br />
                <br />

                <small>Amount to Claim:</small>
                <br />
                <input type="text" value={unclaimedRewardsNet} disabled style={{width:"100%", textAlign:"center"}} />
                <br />
                <br />

                <small>Claim Income Processing Fee:</small>
                <br />
                <input type="text" value={unclaimedRewardsFee} disabled style={{width:"100%", textAlign:"center"}} />


                  <br />

                  <br />
                  {unclaimedRewards > 0 ? (
                    <>
                      {isLoading ? (
                        <SpinnerBox>
                          <Spinner animation="border" variant="primary" />
                        </SpinnerBox>
                      ) : (
                        <button
                          className="hero-button"
                          type="button"
                          onClick={collectIncome}
                        >
                          Claim
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <br />
              <small className="smallText" style={{textAlign:"left"}}>
                <b style={{float:"left"}}> Swapping Income Claiming Fee Requests:</b>
                <br />
                <ul>
                  <li>Up to 99 USDT: 2%</li>
                  <li>From 100 to 999 USDT: 1%</li>
                  <li>1000 USDT onward: 0.5%</li>
                </ul>
              </small>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <Modal show={showCollectGblModal} onHide={handleCloseCollectGblModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                <center>Claim GBLIncome</center>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card ">
                <div className="card-body text-center">
                  <small>Available GBL Income:</small>
<br />
                  <input type="text" value={unclaimedGblRewards} disabled style={{width:"100%", textAlign:"center"}} />

                  <br />

                  <br />
                  {unclaimedGblRewards > 0 ? (
                    <>
                      {isLoading ? (
                        <SpinnerBox>
                          <Spinner animation="border" variant="primary" />
                        </SpinnerBox>
                      ) : (
                        <button
                          className="hero-button"
                          type="button"
                          onClick={collectGblIncome}
                        >
                          Claim
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SwapV3;
